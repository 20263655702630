$primarycolor:      #{'%'}#{'%'}primarycolor#{'%'}#{'%'};
$secondarycolor:      #{'%'}#{'%'}secondarycolor#{'%'}#{'%'};
$linkcolor:      #{'%'}#{'%'}linkcolor#{'%'}#{'%'};
$headingweight:      #{'%'}#{'%'}headingweight#{'%'}#{'%'};
$translucentcolor:      #{'%'}#{'%'}translucentcolor#{'%'}#{'%'};
$menucolor:      #{'%'}#{'%'}menucolor#{'%'}#{'%'};
$buttoncolor:      #{'%'}#{'%'}buttoncolor#{'%'}#{'%'};
$bodycolor:      #{'%'}#{'%'}bodycolor#{'%'}#{'%'};
$menubgcolor:      #{'%'}#{'%'}menubgcolor#{'%'}#{'%'};
$bodyfontweight:      #{'%'}#{'%'}bodyfontweight#{'%'}#{'%'};
$bodyfontsize:      #{'%'}#{'%'}bodyfontsize#{'%'}#{'%'}px;
$font:      #{'%'}#{'%'}font#{'%'}#{'%'};
$bgcolor:      #{'%'}#{'%'}bgcolor#{'%'}#{'%'};