/*
  Link nel footer: impostazioni di sistema - sezione footer
 */
.footer_links{
  padding:0 15px;

  &_list{
    display:flex;

    &.left_horizontally{
      justify-content: flex-start;
      text-align:left;
    }

    &.right_horizontally{
      justify-content: flex-end;
      text-align: right;

      &:last-child{
        margin-right:0 !important;
      }
    }

    &.center_horizontally{
      justify-content: center;
      text-align: center;
    }

    &.vertical_list{
      flex-direction: column;
    }

    &.horizontal_list{
      flex-direction: row;
    }

    li{
      margin-right: 15px;
    }
  }
}

/*
  Blocco elenco categorie con pulsanti grandi
 */
.blockName_big-buttons-and-category-list{
  margin-bottom:30px;

  .row, .bigger-buttons{
    display:flex;

    @media (max-width: 1023px) {
      flex-direction:column;
    }

    @media (min-width: 1024px) {
      flex-direction:row;
    }
  }

  .category-list-box{
    display:flex;
    flex-direction:column;
  }

  .category-list-box, .bigger-buttons{
    flex:1;
  }

  .category-list-box {
    @media (max-width: 1023px) {
      padding: 30px !important;
    }

    @media (min-width: 1024px) {
      padding: 85px 85px 85px 0 !important;
    }
  }

  .bigger-buttons{
    display:flex;
    flex-direction:column;

    div[class^="button_"]{
      display: flex;
      flex:1;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;

      @media (max-width: 1023px) {
        padding: 30px !important;
      }

      @media (min-width: 1024px) {
        padding-left: 85px !important;
      }
    }
  }
}

/*
  Blocco prodotti a scelta
 */
.price-and-available{
  width: 100% !important;
}