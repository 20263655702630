/**
    BOOKING
 */

#ProductBookingBox {

  max-width: 300px;

  .first_letter_2 > .fl {
    height: 30px;
    width: 30px;
    font-size: 16px;
    font-weight: bold;
    margin-top: -3px;
    padding: 2px;
    color: white;
  }

  .openedCal {

    float:left;

    .pika-single {
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      z-index: 0;
    }

    .pika-lendar {

      width: 280px;
      /*font-family:"Lato", Arial, sans-serif;*/

      .pika-label {
        font-size: 20px;
        font-weight: normal;

      }
      .pika-prev,
      .pika-next {
        border: 2px solid #333;
        padding: 3px 13px;

        &.is-disabled {
          visibility: hidden;
        }
      }
      .pika-table {
        th {
          font-size: 14px;

          abbr:hover {
            cursor: default;
          }
        }
        td {
          border: 1px solid #c5e8a5;

          &.is-disabled {
            border-color: #d9dad0;
            .pika-button {
              background: #e3e4df;
              color: #333;
            }
          }
          &.is-empty {
            border-color: #d9dad0;
          }
          &.is-today {
            .pika-button {
              text-decoration: underline;
            }
          }
        }
      }
      .pika-button {
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        font-weight: bold;
        background: #7ec440;
        color: #FFF;
        border-radius: 0;
      }

      .is-selected .pika-button,
      .pika-button:hover {
        background: #33aaff;
        color: #FFF;
        border-radius: 0;
      }

    }

  }

}
