/*
  UTILITIES
*/
.opacity-25 {
  opacity: 0.25;
}
.opacity-50 {
  opacity: 0.50;
}
.opacity-75 {
  opacity: 0.75;
}
.opacity-100 {
  opacity: 1;
}

.max-width-25 {
  max-width: 25%;
}
.max-width-50 {
  max-width: 50%;
}
.max-width-75 {
  max-width: 75%;
}
.max-width-100 {
  max-width: 100%;
}

.z-index-0 {
  z-index: 0;
}
.z-index-5 {
  z-index: 5;
}
.z-index-10 {
  z-index: 10;
}
.z-index-15 {
  z-index: 15;
}
.z-index-20 {
  z-index: 20;
}

.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-auto {
  overflow: auto;
}

.fs_huge{
  font-size:2em;
}
.rating_list li{
  width:15px;
  height:15px;
  cursor:pointer;
}
.rating_list li.fs_huge{
  width:35px;
}

.overflow-visible {
  overflow: visible;
}

.font_line_through {
  text-decoration: line-through;
}

.noborder {
  border: none !important;
}
.nopadding {
  padding: 0 !important;
}

img.grayscale {
  filter: none;
  -webkit-filter: grayscale(100%);
  -moz-filter:    grayscale(100%);
  -ms-filter:     grayscale(100%);
  -o-filter:      grayscale(100%);
  cursor: pointer;
  transition: all 300ms ease;
}
img.grayscale:hover {
  filter: none;
  -webkit-filter: grayscale(0%);
  -moz-filter:    grayscale(0%);
  -ms-filter:     grayscale(0%);
  -o-filter:      grayscale(0%);
}

.d_inline {
  display: inline;
}

.auto-margin {
  margin: auto;
}

.p_relative {
  position: relative;
}

/* BOUNCE EFFECT */

.bounce {
  animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  -moz-animation: bounce 2s infinite;
  -o-animation: bounce 2s infinite;
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);}
  40% {-webkit-transform: translateY(-30px);}
  60% {-webkit-transform: translateY(-15px);}
}

@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {-moz-transform: translateY(0);}
  40% {-moz-transform: translateY(-30px);}
  60% {-moz-transform: translateY(-15px);}
}

@-o-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {-o-transform: translateY(0);}
  40% {-o-transform: translateY(-30px);}
  60% {-o-transform: translateY(-15px);}
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
  40% {transform: translateY(-30px);}
  60% {transform: translateY(-15px);}
}